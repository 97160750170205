.App {
  font-family: sans-serif;
  text-align: center;
}
.bg-info-light {
  background-color: #c1d2e09e !important;
}

radis-14 {
  border-radius: 14px;
}

.read-more::after {
  content: "...ReadMore";
  color: #1d9bf0;
  position: absolute;
  padding-left: 6px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  bottom: 1px;
  display: block;
  background-color: #f7fafc;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.article-card {
  transition: all 0.25s ease;
  border: 2px solid #c3d1da;
  border-radius: 14px;
}

.article-card .card-header {
  border: none;
}

.article-card .card-separator {
  width: calc(100% - 16px);
  border-bottom: 2px solid #c3d1da;
  margin: 0 auto;
  padding: 0 10px;
}

.article-card:hover {
  border-color: #017ac6;
  transform: scale(1.02);
}
