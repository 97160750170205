/* FormFeedbackColors.css */

/* Red color for error messages */
.error-feedback {
  color: #fb6340; /* Bootstrap's danger color */
}

/* Green color for success messages */
.success-feedback {
  color: #28a745; /* Bootstrap's success color */
}

/* Blue color for informational messages */
.info-feedback {
  color: #007bff; /* Bootstrap's primary color */
}